import "./style.css";
import * as dat from "dat.gui";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader.js";
import gsap from 'gsap'
import Stats from 'stats.js'

const TAU = 6.28318530718

const stats = new Stats()
stats.showPanel(0)
document.body.appendChild(stats.dom)

/**
 * Base
 */
// Debug
const debugObject = {};
const gui = new dat.GUI({
  width: 400,
});

// Canvas
const canvas = document.querySelector("canvas.webgl");

// Scene
const scene = new THREE.Scene();

/**
 * Loaders
 */
// Texture loader
const loadingManager = new THREE.LoadingManager();

loadingManager.onStart = () => {
  console.log("loading started");
};
loadingManager.onLoad = () => {
  console.log("loading finished");
};
loadingManager.onProgress = () => {
  console.log("loading progressing");
};
loadingManager.onError = () => {
  console.log("loading error");
};

const textureLoader = new THREE.TextureLoader(loadingManager);

// GLTF loader
const gltfLoader = new GLTFLoader();

/**
 * Light
 */

const light = new THREE.AmbientLight(0x404040)
scene.add( light );

/**
 * Textures
 */
const bakedTexture = textureLoader.load('hallBake.jpg')
bakedTexture.flipY = false
bakedTexture.encoding = THREE.sRGBEncoding

const bakedShelfTexture = textureLoader.load('shelfBake.jpg')
bakedShelfTexture.flipY = false
bakedShelfTexture.encoding = THREE.sRGBEncoding

const coverTextures = {};
const gameBoxMaterial = new THREE.MeshBasicMaterial();

  // Load all cover textures
  (async () => {
    // const req = await fetch("http://localhost:3000/covers");
    // const data = await req.json();

    // const covers = data.data;

    const covers = [
      "102600.jpg",
      "105600.jpg",
      "107410.jpg",
      "1079260.jpg",
      "1091500.jpg",
      "1097150.jpg",
      "1139900.jpg",
      "1151340.jpg",
      "1151640.jpg",
      "1172380.jpg",
      "1190460.jpg",
      "130.jpg",
      "1465360.jpg",
      "17480.jpg",
      "20.jpg",
      "200170.jpg",
      "200710.jpg",
      "201790.jpg",
      "203160.jpg",
      "204100.jpg",
      "207140.jpg",
      "210770.jpg",
      "213610.jpg",
      "214950.jpg",
      "218620.jpg",
      "219640.jpg",
      "220.jpg",
      "220160.jpg",
      "220200.jpg",
      "221100.jpg",
      "221380.jpg",
      "221910.jpg",
      "222880.jpg",
      "22370.jpg",
      "22380.jpg",
      "224580.jpg",
      "226840.jpg",
      "227300.jpg",
      "228380.jpg",
      "230410.jpg",
      "231430.jpg",
      "232810.jpg",
      "234630.jpg",
      "236110.jpg",
      "238960.jpg",
      "239140.jpg",
      "240.jpg",
      "240720.jpg",
      "241540.jpg",
      "242760.jpg",
      "242920.jpg",
      "244850.jpg",
      "24790.jpg",
      "250820.jpg",
      "252490.jpg",
      "252950.jpg",
      "255710.jpg",
      "259080.jpg",
      "261180.jpg",
      "261570.jpg",
      "261640.jpg",
      "265890.jpg",
      "266840.jpg",
      "268500.jpg",
      "269210.jpg",
      "280.jpg",
      "285900.jpg",
      "286160.jpg",
      "286690.jpg",
      "287390.jpg",
      "290790.jpg",
      "294100.jpg",
      "304390.jpg",
      "304930.jpg",
      "310560.jpg",
      "312660.jpg",
      "319630.jpg",
      "320.jpg",
      "326460.jpg",
      "33250.jpg",
      "33350.jpg",
      "33930.jpg",
      "340.jpg",
      "34330.jpg",
      "346110.jpg",
      "360.jpg",
      "361280.jpg",
      "362890.jpg",
      "365720.jpg",
      "367450.jpg",
      "377160.jpg",
      "378860.jpg",
      "380.jpg",
      "38400.jpg",
      "38410.jpg",
      "38420.jpg",
      "387290.jpg",
      "391730.jpg",
      "394510.jpg",
      "400.jpg",
      "4000.jpg",
      "400940.jpg",
      "407530.jpg",
      "41070.jpg",
      "410900.jpg",
      "412020.jpg",
      "414340.jpg",
      "418460.jpg",
      "420.jpg",
      "422420.jpg",
      "427520.jpg",
      "43110.jpg",
      "431240.jpg",
      "433850.jpg",
      "434570.jpg",
      "439700.jpg",
      "440.jpg",
      "442070.jpg",
      "44350.jpg",
      "447020.jpg",
      "457140.jpg",
      "460790.jpg",
      "466560.jpg",
      "4700.jpg",
      "470220.jpg",
      "480490.jpg",
      "493340.jpg",
      "49520.jpg",
      "50.jpg",
      "517710.jpg",
      "524220.jpg",
      "530700.jpg",
      "546560.jpg",
      "548430.jpg",
      "550.jpg",
      "552440.jpg",
      "552520.jpg",
      "557340.jpg",
      "564310.jpg",
      "570.jpg",
      "571740.jpg",
      "572410.jpg",
      "578080.jpg",
      "582160.jpg",
      "594650.jpg",
      "6060.jpg",
      "611500.jpg",
      "611670.jpg",
      "616560.jpg",
      "620.jpg",
      "622460.jpg",
      "632360.jpg",
      "63380.jpg",
      "644930.jpg",
      "648800.jpg",
      "65700.jpg",
      "65720.jpg",
      "674940.jpg",
      "683320.jpg",
      "6880.jpg",
      "690790.jpg",
      "70.jpg",
      "703080.jpg",
      "711540.jpg",
      "71230.jpg",
      "72850.jpg",
      "728880.jpg",
      "730.jpg",
      "745920.jpg",
      "747350.jpg",
      "773951.jpg",
      "793690.jpg",
      "805550.jpg",
      "813780.jpg",
      "8190.jpg",
      "858810.jpg",
      "858820.jpg",
      "870780.jpg",
      "880940.jpg",
      "8870.jpg",
      "892970.jpg",
      "8930.jpg",
      "903950.jpg",
      "905340.jpg",
      "94400.jpg",
      "945360.jpg",
      "976730.jpg",
      "98200.jpg"
    ]

    covers.forEach((cover) => {
      coverTextures[cover] = textureLoader.load(`cover/${cover}`);
      coverTextures[cover].encoding = THREE.sRGBEncoding;
      coverTextures[cover].flipY = false;
    });

    /**
    * GameBox
    */
    gltfLoader.load("gameBox.glb", (gltf) => {
      console.log(gltf)
      const gameBoxGeometry = gltf.scene.children[0].geometry;

      covers.forEach((cover, i) => {
        
        const gameBoxMaterial = new THREE.MeshBasicMaterial({map: coverTextures[cover]});
         const gameBoxMesh = new THREE.Mesh(gameBoxGeometry, gameBoxMaterial)

         gameBoxMesh.position.set((i * 0.575) + 0.25, 1.2, -6.5);
         gameBoxMesh.rotateX(TAU * 0.25 * -1);
         gameBoxMesh.rotateZ(TAU * 0.1 * -1);
       
         scene.add(gameBoxMesh);
     })
     })

     
     
  })();

    /**
    * Hall
    */

    gltfLoader.load('hall_empty.glb', (gltf) => {
      console.log(gltf)
      gltf.scene.children[0].children[0].material = new THREE.MeshBasicMaterial({map: bakedTexture})
      gltf.scene.children[0].children[1].material = new THREE.MeshBasicMaterial({map: bakedTexture})
      
      scene.add(gltf.scene)
    })


  /**
    * Shelf
    */

   gltfLoader.load('shelf.glb', (gltf) => {
    console.log(gltf)
   const shelf = gltf.scene.children[0]
    shelf.material = new THREE.MeshBasicMaterial({map: bakedShelfTexture})

   scene.add(shelf)


  })


/**
 * Sizes
 */
const sizes = {
  width: window.innerWidth,
  height: window.innerHeight,
};

window.addEventListener("resize", () => {
  // Update sizes
  sizes.width = window.innerWidth;
  sizes.height = window.innerHeight;

  // Update camera
  camera.aspect = sizes.width / sizes.height;
  camera.updateProjectionMatrix();

  // Update renderer
  renderer.setSize(sizes.width, sizes.height);
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

  // Update fireflies
  firefliesMaterial.uniforms.uPixelRatio.value = Math.min(
    window.devicePixelRatio,
    2
  );
});

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(
  45,
  sizes.width / sizes.height,
  0.1,
  100
);
camera.position.x = 0;
camera.position.y = 0.3;
camera.position.z = 3;
scene.add(camera);

// Controls
const controls = new OrbitControls(camera, canvas);
controls.enableDamping = true;

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
  canvas: canvas,
  antialias: true,
});
renderer.setSize(sizes.width, sizes.height);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
renderer.outputEncoding = THREE.sRGBEncoding;

debugObject.clearColor = "#eee";
renderer.setClearColor(debugObject.clearColor);
gui.addColor(debugObject, "clearColor").onChange(() => {
  renderer.setClearColor(debugObject.clearColor);
});

/**
 * Animate
 */
const clock = new THREE.Clock();

const tick = () => {
  stats.begin()
  const elapsedTime = clock.getElapsedTime();

  // Update controls
  controls.update();

  // Render
  renderer.render(scene, camera);

  // Call tick again on the next frame
  window.requestAnimationFrame(tick);

  stats.end()
};

tick();
